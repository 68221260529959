import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store';
import history from '../../../history';
import { AppBar, Toolbar, IconButton, Switch, Tooltip, Typography, Chip } from '@material-ui/core';
import { iconify2SVG, unwindDevices } from '../../../helpers/misc';
import baselineContentPasteSearch from '@iconify/icons-ic/baseline-content-paste-search';
import brainCircuit20Regular from '@iconify/icons-fluent/brain-circuit-20-regular';
import { useTranslation } from 'react-i18next';
import BSmart_color from '../../../resources/icons/BSmart.png';
import Mammography_icon from '../../../resources/icons/mammography_dark.png';
import { Autorenew } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  app_bar_drawer: {
    top: 0,
    backgroundColor: theme.palette.complementary.mainDark,
    display: 'flex',
    justifyContent: 'center',
    height: '5%',
    overflow: 'hidden',
    zIndex: theme.zIndex.nav_menu,
    boxShadow: 'none',
    alignContent: 'space-around',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  hidden_header: {
    display: 'none',
  },

  grow_toolbar: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
  },

  navButton: {
    color: '#3c3b3b',
    background: '#46B0B0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px !important',
    width: '4vh',
    height: '4vh',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      background: '#2bd9d9',
      boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 78%)',
    },
  },

  Switch: {
    width: 70,
    height: 34,
    padding: 7,
  },

  switchBase: {
    margin: 1,
    padding: 0,
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `URL('${iconify2SVG(brainCircuit20Regular)}')`,
      },
    },
  },

  thumb: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.secondary.light,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '80%',
      height: '80%',
      left: '10%',
      top: '10%',
      opacity: 0.7,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `URL('${iconify2SVG(baselineContentPasteSearch)}')`,
    },
  },

  track: {
    borderRadius: 26 / 2,
    border: '1px solid #777!important',
    backgroundColor: theme.palette.secondary.dark,
    opacity: 1,
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  chip_reset_filter: {
    position: 'absolute',
    right: '6%',
    fontSize: theme.fonts.responsiveMediumBig,
  },
}));

const DashboardIcon = () => (
  <img src={BSmart_color} alt='b-SMART icon' style={{ height: '1.3em' }} />
);

const ExaminationIcon = () => (
  <img src={Mammography_icon} alt='Examination icon' style={{ height: '1.3em' }} />
);

export default function NavBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tabsIndex, examinationListDrawer } = useSelector((state) => state.gui);
  const history = useHistory();

  return (
    <AppBar
      className={tabsIndex === 2 ? classes.hidden_header : classes.app_bar_drawer}
      position='fixed'
    >
      {!window.location.pathname.includes('ifu') && (
        <Toolbar className={classes.grow_toolbar}>
          <div style={{ display: 'flex' }}>
            <div>
              <Tooltip
                arrow
                placement='left'
                title={
                  <Typography className={classes.tooltip_text} color='inherit'>
                    {window.location.pathname.includes('dashboard') ? t('Menu.view_patient') : t('Menu.view_dashboard')}
                  </Typography>
                }
              >
                <IconButton
                  aria-label='nav-navigation'
                  className={classes.navButton}
                  size='small'
                  onClick={() => {
                      if(window.location.pathname.includes('dashboard')) {
                        history.push('/bbox/results/details');
                      } else {
                        history.push('/bbox/results/dashboard');
                      }
                    }
                  }
                >
                  {window.location.pathname.includes('dashboard') ? <ExaminationIcon /> : <DashboardIcon />}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Toolbar>
      )}
      {props.activeFilter && (
        <Chip
          icon={<Autorenew />}
          className={classes.chip_reset_filter}
          label={t('Menu.reset_filter')}
          color={'secondary'}
          variant='outlined'
          size='small'
          onClick={() => dispatch(actions.resetFilter())}
        />
      )}
    </AppBar>
  );
}
