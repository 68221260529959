import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../../store';
import { Grid, Typography, Tooltip, Fab, Divider } from '@material-ui/core';
import { useAppliedEvaluation, useEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import DensityButtons from './DensityButtons';

const useStyles = makeStyles((theme) => ({
  projectionZoomedContainer: {
    width: '92%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  densityButtonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '10px',
  },
  projections_container_cc: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '94%',
  },

  projections_container_mlo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid gray',
    width: '94%',
  },

  projection_text: {
    fontSize: '0.8rem',
    minWidth: '35px',
    color: '#c2c2c2',
  },

  density_fabs: {
    margin: '6px 10px',
    height: '32px',
    width: '32px',
    minHeight: '32px',
    minWidth: '32px',
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },

  projectionGridContainer: {
    height: '15vh',
  },
}));

const DensitySection = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    projections: projData,
    applied_density,
    confirmed_density,
    shown_density,
  } = useSelector((state) => state.results);

  const theme = useTheme();
  const { labeling, results, gui } = useSelector((state) => state);
  const { localChangesDensity, densityInRevisionMode } = useSelector((state) => state.labeling);
  const currentExam = useSelector(
    (state) => state.examinations.currentExam,
    (a, b) => a === b
  );

  const currentDensityShown = shown_density || applied_density;
  const resultDensityEvaluation = results?.evaluations?.density?.[shown_density];

  const resultEvaluation = useAppliedEvaluation(
    'density',
    currentDensityShown,
    densityInRevisionMode
  );
  const { projections, evaluations } = results;
  const { zoomedProjection } = gui;

  useEffect(() => {
    dispatch(actions.hideAllLesions());
  }, [densityInRevisionMode]);

  // Functions
  const getTooltipText = (projName, feature) => {
    if (!evaluations?.density?.hasOwnProperty(currentDensityShown))
      return t('ResultView.not_available');

    if (!densityInRevisionMode)
      return (
        <p style={{ fontSize: '1.3vh' }}>
          {`${projName?.toUpperCase()}: ACR ${resultEvaluation?.[projName]?.DensClass}`}
        </p>
      );

    const projections = {
      rcc: {
        predicted: evaluations.density?.[currentDensityShown]?.rcc ?? {},
        unsaved: resultEvaluation.rcc,
        applied: labeling?.customDensity?.rcc ?? {},
      },
      lcc: {
        predicted: evaluations.density?.[currentDensityShown]?.lcc ?? {},
        unsaved: resultEvaluation.lcc,
        applied: labeling?.customDensity?.lcc ?? {},
      },
      rmlo: {
        predicted: evaluations.density?.[currentDensityShown]?.rmlo ?? {},
        unsaved: resultEvaluation.rmlo,
        applied: labeling?.customDensity?.rmlo ?? {},
      },
      lmlo: {
        predicted: evaluations.density?.[currentDensityShown]?.lmlo ?? {},
        unsaved: resultEvaluation.lmlo,
        applied: labeling?.customDensity?.lmlo ?? {},
      },
    };

    try {
      // Unsaved changes
      if (!projections[projName].predicted.hasOwnProperty(feature))
        return t('ResultView.not_available');

      if (
        localChangesDensity &&
        projections[projName]?.applied[feature] !== projections[projName]?.unsaved[feature]
      )
        return (
          <>
            <p style={{ fontSize: '1.3vh' }}>{t('ResultView.unsaved')}</p>
            <p style={{ fontSize: '1.3vh' }}>
              {`${t('ResultView.org_pred')}: ACR ${projections[projName]?.predicted[feature]}`}
            </p>
          </>
        );

      // Revised
      if (
        Object.keys(evaluations).length > 1 &&
        applied_density !== 'bbox' &&
        projections[projName]?.applied &&
        projections[projName]?.applied[feature] !== projections[projName]?.predicted[feature]
      ) {
        if (Object.keys(evaluations).length === 1) return 'No active revision';

        return (
          <>
            <p style={{ fontSize: '1.3vh' }}>{`${t('ResultView.relabeled')} ${applied_density}`}</p>
            <p style={{ fontSize: '1.3vh' }}>
              {`${t('ResultView.org_pred')}: ACR ${projections[projName]?.predicted[feature]}`}
            </p>
          </>
        );
      }

      return (
        <>
          <p style={{ fontSize: '1.3vh' }}>
            {`${t('ResultView.org_pred')}: ACR ${projections[projName]?.predicted[feature]}`}
          </p>
        </>
      );
    } catch {
      return t('ResultView.not_available');
    }
  };

  const renderDensityButton = (label, densClass, projection, region, borderType) => {
    return (
      <Grid
        item
        xs={6}
        style={{ borderRight: projection.includes('r') && '1px solid gray', height: '87%' }}
      >
        <DensityButtons
          dens_class={densClass?.DensClass || densClass}
          setDensClass={(dens) =>
            densClass && densityInRevisionMode ? dispatch(actions.updateDensity(region, dens)) : {}
          }
          tooltipText={
            !!projData[projection]
              ? getTooltipText(projection.toLowerCase(), 'DensClass')
              : t('ResultView.not_available')
          }
          label={t(label)}
        />
      </Grid>
    );
  };

  const renderZoomedDensityButtons = () => {
    const projectionData = projData[zoomedProjection.toLowerCase()];
    const custom = {
      RCC: resultEvaluation?.rcc,
      LCC: resultEvaluation?.lcc,
      RMLO: resultEvaluation?.rmlo,
      LMLO: resultEvaluation?.lmlo,
    };
    const densClass = custom[zoomedProjection.toUpperCase()]?.DensClass;
    const tooltipText = projectionData
      ? getTooltipText(zoomedProjection, 'DensClass')
      : t('ResultView.not_available');
    const label = t(`Dashboard.${[zoomedProjection]}`);

    return (
      <Grid item xs={10} className={classes.projectionGridContainer}>
        <DensityButtons
          dens_class={densClass}
          setDensClass={(dens) =>
            resultEvaluation?.rcc
              ? dispatch(actions.updateDensity(zoomedProjection.toUpperCase(), dens))
              : {}
          }
          tooltipText={tooltipText}
          label={label}
        />
      </Grid>
    );
  };

  return (
    <div className={classes.densityButtonsContainer}>
      {zoomedProjection ? (
        <Grid item xs={12} className={classes.projectionZoomedContainer}>
          {renderZoomedDensityButtons()}
        </Grid>
      ) : (
        <>
          <Grid
            container
            direction='row'
            spacing={1}
            className={classes.projections_container_cc}
            style={{ height: densityInRevisionMode && '40vh' }}
          >
            {renderDensityButton(
              'Dashboard.rcc',
              resultEvaluation?.rcc || resultEvaluation?.rcc?.DensClass,
              'rcc',
              'RCC'
            )}
            {renderDensityButton(
              'Dashboard.lcc',
              resultEvaluation?.lcc || resultEvaluation?.lcc?.DensClass,
              'lcc',
              'LCC'
            )}
          </Grid>
          <Grid
            container
            direction='row'
            spacing={1}
            className={classes.projections_container_mlo}
            style={{ height: densityInRevisionMode && '40vh' }}
          >
            {renderDensityButton(
              'Dashboard.rmlo',
              resultEvaluation?.rmlo || resultEvaluation?.rmlo?.DensClass,
              'rmlo',
              'RMLO'
            )}
            {renderDensityButton(
              'Dashboard.lmlo',
              resultEvaluation?.lmlo || resultEvaluation?.lmlo?.DensClass,
              'lmlo',
              'LMLO'
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default DensitySection;
