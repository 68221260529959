import { Chip, Divider, Tooltip, Typography } from '@material-ui/core';
import useResponsive from './hooks/useResponsive';
import { useQualityTranslation } from './hooks/useQualityTranslation';

export function qualityColor(currentPGMI, theme) {
  if (!currentPGMI) {
    return 'gray';
  }

  const currentPGMILowerCase = currentPGMI?.toLowerCase();
  if (currentPGMILowerCase === 'adequate') return theme.palette.projections.adequate;
  if (currentPGMILowerCase === 'inadequate') return theme.palette.projections.inadequate;
  if (currentPGMILowerCase === 'perfect') return theme.palette.projections.perfect;
  if (currentPGMILowerCase === 'good') return theme.palette.projections.good;
  if (currentPGMILowerCase === 'moderate') return theme.palette.projections.moderate;
  if (currentPGMILowerCase === 'limited') return theme.palette.projections.moderate;
  if (currentPGMILowerCase === 'insufficient') return theme.palette.projections.inadequate;

  return '#424242';
}

const viewPositionText = (projection, t) => {
  if (projection === 'rcc') {
    return t('Dashboard.rcc').toUpperCase();
  }
  if (projection === 'lcc') {
    return t('Dashboard.lcc').toUpperCase();
  }
  if (projection === 'rmlo') {
    return t('Dashboard.rmlo').toUpperCase();
  }
  if (projection === 'lmlo') {
    return t('Dashboard.lmlo').toUpperCase();
  }
};

export const qualityEvaluationChip = (
  view_position,
  qualityResult,
  qualityExplanation,
  zoomedProjection,
  onPGMIClick,
  smallView,
  qualityInfoMode,
  qualityInRevison,
  theme,
  t
) => {
  const quality = useQualityTranslation(qualityResult, t);
  let qualityCapitalized = t('ResultView.missing_evaluated');

  console.log('qualityEvaluationChip', { qualityCapitalized, quality });

  if (!!qualityResult && qualityResult.length > 0)
    qualityCapitalized = quality[0].toUpperCase() + quality.slice(1, quality.length);

  return (
    <Tooltip
      arrow
      title={
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${viewPositionText(view_position, t)} ${t(
              'ResultView.quality'
            )}: ${qualityCapitalized}`}
          </Typography>
          {qualityExplanation && qualityExplanation.length > 0 && !qualityInRevison && (
            <>
              <Divider style={{ margin: '3% 0' }} />
              {qualityExplanation.map((explanation, index) => (
                <Typography
                  key={index}
                  style={{
                    letterSpacing: '0.1rem',
                    fontSize: theme.fonts.responsiveMedium,
                  }}
                >
                  {`- ${explanation}`}
                </Typography>
              ))}
            </>
          )}
        </>
      }
    >
      <Chip
        variant='default'
        onClick={() => {
          if (onPGMIClick && qualityResult !== null && qualityInRevison) onPGMIClick();
        }}
        label={
          <Typography
            style={{
              fontSize: '1.2vh',
              letterSpacing: '0.1rem',
              lineHeight: 'normal',
            }}
          >
            {quality?.toUpperCase() ?? t('ResultView.is_not_evaluated')}
          </Typography>
        }
        size='small'
        style={{
          zIndex: 2000,
          transform: zoomedProjection
            ? undefined
            : view_position === 'lcc' || view_position === 'lmlo'
            ? 'rotate(90deg)'
            : 'rotate(-90deg)',
          transformOrigin: 'center',
          background: qualityColor(qualityResult, theme),
          position: 'fixed',
          top: zoomedProjection
            ? '100%'
            : view_position === 'rmlo' || view_position === 'lmlo'
            ? '60%'
            : '50%',
          left: zoomedProjection
            ? view_position === 'lcc' || view_position === 'lmlo'
              ? '20%'
              : undefined
            : view_position === 'lcc' || view_position === 'lmlo'
            ? smallView
              ? '95%'
              : '95%'
            : undefined,
          right: zoomedProjection
            ? view_position === 'lcc' || view_position === 'lmlo'
              ? undefined
              : '20%'
            : view_position === 'lcc' || view_position === 'lmlo'
            ? undefined
            : smallView
            ? '95%'
            : '95%',
          height: '18px',
          minWidth: '130px',
          cursor: qualityInRevison ? 'pointer' : 'help',
        }}
      />
    </Tooltip>
  );
};
