import React, { useEffect, useState } from 'react';
import { Drawer, Grid, IconButton, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import TuneIcon from '@material-ui/icons/Tune';
import * as actions from '../../../store/index';
import CloseIcon from '@material-ui/icons/Close';
import SearchBar from './SearchBar/SearchBar';
import DatePicker from './DatePicker/DatePicker';
import CustomDatePicker from './DatePicker/CustomDatePicker';
import ExaminationsListTable from './Tables/ExaminationsListTable';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    width: theme.drawerWidth,
    height: '100vh',
    zIndex: 200,
    border: 'none',
    paddingTop: '2%',
    background:
      'linear-gradient(180deg, rgba(66,66,66,1) 0%, rgb(75 75 75) 50%, rgba(66,66,66,1) 100%)',
  },

  drawerPaperLight: {
    position: 'relative',
    width: theme.drawerWidth,
    height: '100vh',
    zIndex: 200,
    border: 'none',
    paddingTop: '2%',
    background: 'linear-gradient(180deg, #ffffff 0%, #EEF2F6 50%, #ffffff 100%)',
  },

  filters_dates_container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '2%',
    marginTop: 'calc(12% + 10px)',
  },

  filters_dates_container_revising: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '2%',
    marginTop: '8px',
  },

  filtersGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  filterDatesGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '30%',
    height: '5vh',
    alignItems: 'center',
  },

  inputSearchContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '5vh',
    alignItems: 'center',
    width: '65%',
  },

  close_button: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  menuButton: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
  },

  menuButtonClose: {
    margin: theme.spacing(0.5),
    marginTop: '0',
    padding: '3px',
  },

  filterIcon: {
    display: 'flex',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
}));

export default function DrawerExamList(props) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Store state
  const filter = useSelector((state) => state.filter);
  const dates = useSelector((state) => state.dates);
  const { activeDialog, tabsIndex, darkState, examinationListDrawer } = useSelector(
    (state) => state.gui
  );

  useEffect(() => {
    dispatch(actions.setCompressionDates(null));
    dispatch(actions.setPerformanceDates(null));
  }, [dates.customDate, dates.selectedRange]);

  return (
    <div className={classes.filterDrawerContainer}>
      <Drawer
        variant='persistent'
        anchor='left'
        open={examinationListDrawer}
        classes={{
          paper: darkState ? classes.drawerPaper : classes.drawerPaperLight,
        }}
        data-test-id='patient-list-drawer'
        transitionDuration={800}
      >
        <div
          className={
            tabsIndex === 2
              ? classes.filters_dates_container_revising
              : classes.filters_dates_container
          }
        >
          <div className={classes.filtersGroup}>
            <div className={classes.inputSearchContainer}>
              <SearchBar placeholder={t('Layout.search')} activeFilter={props.activeFilter} />
            </div>
            <div className={classes.filterDatesGroup}>
              <DatePicker
                setDates={(dates) => dispatch(actions.setFilterProperty(['dates', dates]))}
                customDate={dates.customDate}
                setCustomDate={(dates) => dispatch(actions.setCustomDate(date))}
                selectedRange={dates.selectedRange}
                setSelectedRange={(date) => dispatch(actions.setSelectedRange(date))}
              />
            </div>
            <div className={classes.close_button}>
              <Tooltip
                arrow
                placement='right'
                title={
                  <Typography color='inherit' className={classes.tooltip_text}>
                    {t('Console.close')}
                  </Typography>
                }
                aria-label='add'
              >
                <IconButton
                  edge='start'
                  className={classes.menuButtonClose}
                  color='inherit'
                  aria-label='menu'
                  onClick={() => dispatch(actions.setExaminationListDrawer(false))}
                  size='small'
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {dates.selectedRange === 'custom' && (
            <CustomDatePicker
              setDates={(dates) => dispatch(actions.setFilterProperty(['dates', dates]))}
              customDate={dates.customDate}
              setCustomDate={(date) => dispatch(actions.setCustomDate(date))}
            />
          )}
        </div>

        <ExaminationsListTable />
      </Drawer>
    </div>
  );
}
