import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  filterDrawerContainer: {
    background: 'gray',
    opacity: 0.5,
  },

  menuButton: {
    margin: 0,
  },

  drawerWidthContainer: {
    width: '600px',
  },

  filterHeader: {
    display: 'flex',
    justifyContent: ' space-between',
    backgroundColor: theme.palette.complementary.darker,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },

  optionsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },

  filterOptions: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  listItemTitleContainer: {
    borderTop: '1px solid gray',
    display: 'flex',
    margin: '2px',
  },

  listOperatorTitleContainer: {
    display: 'flex',
    margin: '2px',
    width: '100%',
  },

  listDevicesTitleContainer: {
    margin: '7px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
    width: '100%',
  },

  devicesButtons: {
    display: 'flex',
    width: '100%',
    marginTop: '5px',
    alignItems: 'center',
    gridColumn: '2 / 4',
    justifyContent: 'flex-end',
    paddingRight: '16px',
  },

  listItemText: {
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 'bold',
    color: theme.palette.texts.primary,
    inlineSize: 'min-content',
  },

  listDevicesText: {
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 'bold',
    color: theme.palette.texts.primary,
    inlineSize: 'min-content',
    gridColumn: '1',
    fontSize: theme.fonts.responsiveSmall,
  },

  customFilterText: {
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 'bold',
    color: theme.palette.texts.primary,
    fontSize: theme.fonts.responsiveSmall,
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },

  filterDrawer_MainButtons: {
    display: 'flex',
    alignItems: 'center',
  },

  addRuleButton: {
    marginRight: '3%',
    fontSize: theme.fonts.responsiveSmall,
    color: '#FFCDD2',
  },

  selectButton_accordion: {
    margin: '1px',
    color: '#FFCDD2',
    fontSize: theme.fonts.responsiveSmall,
    width: '4vw',
    '&:hover': {
      background: '#EF9A9A',
      color: '#424242',
    },
  },

  selectButton: {
    margin: '0 50px',
    color: '#FFCDD2',
    fontSize: theme.fonts.responsiveSmall,
    width: '4vw',
    '&:hover': {
      background: '#EF9A9A',
      color: '#424242',
    },
  },

  selectButton_unselect: {
    fontWeight: 100,
    width: '7vw',
    height: '3vh',
    margin: '2px',
    background: '#e5e1e1',
    width: '8vw',
    fontSize: theme.fonts.responsiveSmall,

    '&:hover': {
      background: '#d19e9b',
    },
  },

  InstitutionsButton: {
    marginLeft: 'auto',
  },

  filterButtonsDetail: {
    display: 'flex',
    justifyContent: 'space-around',
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
    margin: '8px',
    minWidth: '130px',

    '&:hover': {
      backgroundColor: '#FFCDD2',
    },
  },

  optionsGridContainer: {
    display: 'grid',
    width: '542px',
    marginLeft: '24px',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  studyList: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  radio_list_container: {
    display: 'flex',
    alignItems: 'center',
    width: '31%',
  },

  list_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  nothing_found: {
    fontSize: theme.fonts.responsiveSmall,
    fontStyle: 'italic',
    color: 'gray',
  },
}));
